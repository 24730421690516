<template>
  <div class="nav-footer">
    <!-- 定宽布局 -->
    <div class="container">
      <!-- 联系方式-->
      <div class="f-info">
        <h2>联系方式</h2>
        <div class="blink"></div>
        <ul>
          <li class="iconfont icon-jigou">
            公司：北京光合起源网络科技有限公司
          </li>
          <!-- <li class="iconfont icon-weibiaoti-">电话：	13315157171</li> -->
          <li class="iconfont icon-youxiang">网站名称：光合起源</li>
          <li class="iconfont icon-youxiang">
            <a href="https://beian.miit.gov.cn/#/Integrated/index">备案号：京ICP备15038749号-2</a>
          </li>
          <li class="iconfont icon-dizhi">
            地址：北京市海淀区蓝靛厂东路2号院2号楼(金源时代商务中心2号楼)九层1单元(A座)10F
          </li>
        </ul>
      </div>
      <!-- 公司简介 -->
      <div class="f-jj">
        <h2>北京光合起源网络科技有限公司</h2>
        <p>Beijing Guanghe Origin Network Technology Co., Ltd</p>
        <div class="blink"></div>
        <p>
          经营范围包括技术开发、技术转让、技术咨询、技术服务；计算机技术培训；基础软件服务；应用软件服务；计算机系统服务；销售计算机、软件及辅助设备；从事互联网文化活动等......
        </p>
      </div>
      <!-- 导航栏 -->
      <div class="f-list">
        <h2>导航</h2>
        <div class="blink"></div>
        <ul>
          <li><a href="/">网站首页</a></li>
          <li><a href="/#/ac1">行业资讯</a></li>
          <!-- <li><a href="/#/ac2">纠纷处理</a></li> -->
          <!-- <li><a href="/#/xy">用户协议</a></li> -->
          <!-- <li><a href="/#/ac3">用户权益保障措施</a></li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-footer",
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.nav-footer {
  width: 100%;
  height: 350px;
  background: #2d3237;

  margin-top: 40px;

  .container {
    height: 100%;
    @include flex();

    // 联系方式
    .f-info {
      h2 {
        font-size: 18px;
        color: #fff;
      }

      .blink {
        width: 100px;
        height: 3px;
        background-color: #fff;
        margin: 20px 0;
      }

      ul {
        li {

          font-size: 14px;
          color: rgba(255, 255, 255, 0.4);
          margin: 10px 0;

          a {
            color: rgba(255, 255, 255, 0.4);
            font-size: 14px;
          }
        }
      }
    }

    // 公司简介
    .f-jj {
      width: 400px;

      h2 {
        font-size: 18px;
        color: #fff;
      }

      .blink {
        width: 100px;
        height: 3px;
        background-color: #fff;
        margin: 20px 0;
      }

      p {
        color: rgba(255, 255, 255, 0.4);
        font-size: 14px;
        line-height: 30px;
      }
    }

    // 导航栏
    .f-list {
      h2 {
        font-size: 18px;
        color: #fff;
      }

      .blink {
        width: 100px;
        height: 3px;
        background-color: #fff;
        margin: 20px 0;
      }

      ul {
        li {
          width: 200px;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.4);
          margin: 10px 0;

          a {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }
    }
  }
}
</style>