<template>
  <div class="index">
    <div class="container">
      <!-- 资讯1 -->
      <div class="ac1">
        <!-- 左侧 -->
        <div class="a1-l">
          <!-- 左侧 -->
          <div class="l">
            <img src="/imgs/n1.jpg" alt="" />
          </div>
          <!-- 右侧 -->
          <div class="r">
            <!-- 循环项 -->
            <div class="r-item" v-for="(item, index) in this.d4" :key="index" @click="gopay();">
              <img :src="item.img" alt="" />
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="a1-r">
          <img src="/imgs/n2.jpg" alt="" />
        </div>
      </div>


      <!-- 资讯区块2 -->
      <div class="ac2">
        <!-- 左侧 -->
        <div class="a2-l">
          <!-- 资讯3 -->
          <div class="a3">
            <!-- 标题 -->
            <div class="a3-title"><h2>收费推荐</h2></div>
            <!-- 循环项 -->
            <div
              class="a3-item"
              v-for="(item, index) in this.d2"
              :key="index"
              @click="gopay()"
            >
              <div class="item-id">{{ item.id }}</div>
              <div class="item-title">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="a2-r">
          <!-- 资讯区块1 -->
          <div class="a1">
            <!-- 标题 -->
            <div class="a1-title">
              <h2>收费资讯</h2>
              <h4></h4>
            </div>

            <!-- 循环项 -->
            <div
              class="a1-item"
              v-for="(item, index) in this.d1"
              :key="index"
              @click="gopay()"
            >
              <!-- 标题 -->
              <div class="title">
                {{ item.title }}
              </div>
              <!-- 中层 -->
              <div class="item-con">
                <img :src="item.img" alt="" />
                <div class="item-jj">
                  {{ item.jj }}
                </div>
              </div>
              <!-- 信息层 -->
              <div class="item-info">
                <!-- 左侧 -->
                <div class="item-l">
                  <span>{{ item.author }}</span>
                  <span> ({{ item.sub }})阅读 </span>
                </div>
                <!-- 右侧 -->
                <div class="item-r">收费阅读:2元/篇</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹框 -->
    <modal
      title="请付费阅读"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="msub()"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img style="" src="/imgs/pay2.png" alt="" />
        <p>付费阅读：2元</p>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal.vue";
export default {
  components: {
    Modal,
    // info,
  },
  data() {
    return {
      href: "index.html",
      d1: [],
      d2: [],
      d3: [],
      d4: [],
      myData: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init1();
    document.title = "光合起源";
  },
  methods: {
    init1() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        // 咨询区块1
        this.d1 = data.slice(0, 5);
        this.d2 = data.slice(4, 15);
        this.d3 = data.slice(8, 20);
        this.d4 = data.slice(6, 8);
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      alert("资讯每篇2元，点击确定购买");
      this.showModal = true;

      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      //   console.log(this.showModal);
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("资讯每篇2元，请登录购买~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  width: 100%;
  // height: 800px;
  position: relative;
  background-color: #f2f2f2;
  padding-top: 30px;
  box-sizing: border-box;
  .container {
    // 资讯1
    .ac1 {
      width: 100%;
      height: 380px;
      // background: pink;
      // @include flex();
      display: flex;
      justify-content: space-between;
      align-items: center;
      // 左侧
      .a1-l {
        width: 60%;
        height: 100%;
        // background-color: #c60023;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .l {
          width: 70%;
          height: 100%;
          background-color: #ff6600;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .r {
          width: 30%;
          height: 100%;
          background-color: #000;
          margin-left: 15px;
          .r-item {
            display: block;
            width: 100%;
            height: 185px;
            background-color: #fff;
            margin-bottom: 5px;
            position: relative;
            &:hover {
              p {
                opacity: 1;
              }
            }
            img {
              width: 100%;
              height: 100%;
            }

            p {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 20px;
              line-height: 20px;
              background-color: rgba(255, 255, 255, 0.6);
              opacity: 0;
              transition: all 0.2s;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      // 右侧
      .a1-r {
        width: 40%;
        height: 100%;
        background-color: #ff6600;
        margin-left: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    // 资讯区块2
    .ac2 {
      width: 100%;
      // height: 800px;
      display: flex;
      justify-content: space-between;
      margin: 30px 0;
      // 左侧
      .a2-l {
        width: 30%;
        height: 100%;
        // background-color: #c60023;
        .a3 {
          margin: 30px 0;
          //   标题
          .a3-title {
            width: 100%;
            height: 40px;
            // padding: 20px 0;
            h2 {
              //   font-size: 16px;
              color: #333;
              font-weight: 400;
              border-bottom: 1px solid #ccc;
              line-height: 30px;
              height: 100%;
            }
          }

          //   循环项
          .a3-item {
            width: 100%;
            height: 40px;
            margin: 15px 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover {
              .item-title {
                color: $colorZ;
              }
            }
            &:nth-child(2) {
              .item-id {
                background: #c60023;
              }
            }
            &:nth-child(3) {
              .item-id {
                background: #ff6600;
              }
            }
            &:nth-child(4) {
              .item-id {
                background: #f5d526;
              }
            }
            // ID图标
            .item-id {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background-color: #ccc;
              text-align: center;
              line-height: 30px;
              font-size: 16px;
              color: #fff;
              margin-right: 15px;
            }

            .item-title {
              width: 80%;
              // background-color: #c60023;
              font-size: 14px;
              color: #333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              transition: all 0.2s;
            }
          }
        }
      }
      // 右侧
      .a2-r {
        width: 70%;
        height: 100%;
        // background-color: #ff6600;
        margin-left: 30px;
        //   资讯区块1
        .a1 {
          width: 100%;
          // height: 600px;

          margin-top: 30px;
          // 标题
          .a1-title {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ccc;
            h2 {
              color: #333;
            }
          }

          // 循环项
          .a1-item {
            width: 100%;
            height: 200px;
            padding: 5px;
            box-sizing: border-box;
            margin: 30px 0;
            transition: all 0.2s;
            border: 2px solid #fff;
            padding: 10px;
            box-sizing: border-box;
            &:hover {
              // box-shadow: 0px 0px 8px #888;
              // transform: translateY(-10px);
              background-color: #fff;
              border: 2px dashed #ccc;
              .title {
                color: $colorZ;
              }
            }
            //   标题
            .title {
              width: 100%;
              font-size: 14px;
              color: #333;
              font-weight: 400;
              cursor: pointer;
              transition: all 0.2s;
              &:hover {
                color: $colorZ;
              }
            }
            //   中层
            .item-con {
              width: 100%;
              height: 120px;
              margin: 10px 0;
              // background: #ff5544;
              display: flex;
              align-items: center;
              // 图品
              img {
                width: 200px;
                height: 100%;
              }
              .item-jj {
                margin-left: 20px;
                width: 100%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                font-size: 12px;
                color: #888;
                line-height: 30px;
              }
            }
            //   信息层
            .item-info {
              height: 30px;
              width: 100%;
              // background-color: #451215;
              @include flex();
              .item-l {
                span {
                  color: #888;
                  font-size: 12px;
                  margin-right: 30px;
                  i {
                    font-size: 14px;
                    margin-right: 5px;
                  }
                }
              }
              .item-r {
                font-size: 12px;
                color: #888;
              }
            }
          }
        }
      }
    }
  }
}
</style>