<template>
  <div class="ac2">
    <div class="container">
      <!-- 左侧 -->
      <div class="a2-l">
        <!-- banner区块 -->
        <div class="a2-banner">
          <img src="/imgs/b2.jpg" alt="" />
        </div>

        <!-- 循环项 -->
        <div
          class="a2-item"
          v-for="(item, index) in this.data2"
          :key="index"
          @click="gopay()"
        >
          <!-- 左侧图片 -->
          <img :src="item.img" alt="" />
          <!-- 右侧信息 -->
          <div class="item-info">
            <!-- 标题 -->
            <div class="item-title">{{ item.title }}</div>
            <!-- 收费 -->
            <div class="item-pay">收费阅读：2元/篇</div>
            <!-- 简介 -->
            <div class="item-jj">
              {{ item.jj }}
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="a2-r">
        <!-- 标题 -->
        <div class="a2-title">热门收费</div>
        <!-- 循环项 -->
        <div
          class="a2-r-item"
          v-for="(item, index) in this.data"
          :key="index"
          @click="gopay()"
        >
          <!--左侧小圆点 -->
          <div class="item2-y"></div>
          <div class="item2-title">{{ item.title }}</div>
        </div>

        <!-- 图片 -->
        <div class="r-img">
          <img src="" alt="" />
        </div>

        <!-- 图片 -->
        <div class="r-img">
          <img src="" alt="" />
        </div>
      </div>
    </div>
    
    <!-- 弹框 -->
    <modal
      title="请付费阅读"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="msub()"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img style="" src="/imgs/pay2.png" alt="" />
        <p>付费阅读：2元</p>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      data: [],
      data2: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
    this.init2();
    document.title = "光合起源";
  },
  methods: {
    init() {
      this.axios.get("/mock/info.json").then((res) => {
        const data = res.data;
        this.data = data.splice(0, 7);
      });
    },
    init2() {
      this.axios.get("/mock/ac1.json").then((res) => {
        // const data = 
        this.data2 = res.data.splice(0, 10);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 收费逻辑
    gopay() {
      alert("资讯每篇2元，点击确定购买");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("资讯每篇2元，请登录购买~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac2 {
  width: 100%;
  // height: 600px;
  // margin-top: 100px;
  background-color: #f5f5f5;
  .container {
    height: 100%;
    // @include flex();
    display: flex;
    justify-content: space-between;
    margin: 30px auto;
    // 左侧
    .a2-l {
      width: 75%;
      height: 100%;
      // background-color: pink;

      //banner 图片
      .a2-banner {
        width: 100%;
        height: 350px;
        // background-color: #ff6600;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      // 循环项
      .a2-item {
        width: 100%;
        height: 200px;
        background-color: #fff;
        margin: 20px auto;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        // 左侧图片
        img {
          width: 150px;
          height: 150px;
        }
        // 右侧信息
        .item-info {
          margin-left: 30px;
          // 标题
          .item-title {
            font-size: 16px;
            font-weight: bold;
            color: #484848;
          }
          // 收费
          .item-pay {
            font-size: 14px;
            color: #c60023;
            margin: 20px 0;
            cursor: pointer;
          }
          // 简介
          .item-jj {
            width: 500px;
            font-size: 12px;
            color: #555;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
        }
      }
    }
    // 右侧
    .a2-r {
      width: 25%;
      height: 100%;
      margin-left: 10px;
      background-color: #fff;
      padding: 15px;
      box-sizing: border-box;
      // 标题
      .a2-title {
        font-size: 16px;
        font-weight: bold;
        color: #555;
        border-bottom: 1px solid #1985ff;
        width: 100%;
        height: 40px;
        line-height: 40px;
        margin-bottom: 20px;
      }
      // 循环项
      .a2-r-item {
        width: 100%;
        height: 30px;
        // background-color: pink;
        margin: 20px auto;
        display: flex;
        align-items: center;
        cursor: pointer;
        // 左侧原点
        .item2-y {
          width: 8px;
          height: 8px;
          background-color: #1985ff;
          border-radius: 50%;
          margin-right: 10px;
        }
        //标题
        .item2-title {
          font-size: 12px;
          color: #555;
        }
      }
    }
  }
}
</style>
